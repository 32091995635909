import { useDogxContext } from "@libs/dogx";
import { getCookie } from "@libs/utils/helpers/getSafeCookies";

export function useRenegAC() {
  const { getExperimentVariant } = useDogxContext();
    const withRenegAc = getExperimentVariant('dx-reneg-acordocerto') === 'with-acordocerto';
    const isLoggedAc = getCookie('TOKEN')
    const enableRenegAC = isLoggedAc && withRenegAc

  return {
    enableRenegAC
  }
}
